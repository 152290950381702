.about-container {
    grid-area: container;
    display: grid;
    grid-template:
    'left-top' auto
    'left-bottom' auto
    'right-top' auto
    'right-bottom' auto
    / auto;
    @include desktop() {
        margin-top: 2.5em;
        grid-template:
        'left-top   . right-top' auto
        'left-bottom . right-bottom' auto
        / auto minmax(0.5em, 15em) auto;
    }
}

.about-main-title {
    font-weight: 300;
    @include desktop() {
        display: none;
    }
}

.about-left-top-column {
    grid-area: left-top;
    @include desktop() {
        max-width: 23em;
        display: flex;
        flex-direction: column;
        align-items: initial;
        justify-content: flex-start;
    }

    .resume-button {
        font-size: 1.7em;
        line-height: 1.9em;
        @include desktop() {
            margin-top: 2em;
            margin-bottom: 0;
        }
    }
}

.about-left-bottom-column {
    grid-area: left-bottom;
    @include desktop() {
        max-width: 23em;
        display: flex;
        flex-direction: column;
        align-items: initial;
        justify-content: flex-start;
    }

    .about-sub-title {
        margin-bottom: 0.1em;
    }
}

.about-main-title {
    color: #424242;
    line-height: 1.5em;
    margin: 0.9em auto;
    @include desktop() {
        margin: 0.6em auto;
    }
}

.about-sub-title {
    color: textColor(primary-text);
    margin: 0.9em auto;
    font-weight: 300;
    @include desktop() {
        margin: 0.6em auto;
        margin-left: 0;
    }
}

.about-right-column {
    .about-sub-title {
        @include desktop() {
            margin-top: 3em;
        }
    }
}

.about-introduction {
    color: textColor(secondary-text);
    margin: 0.9em auto;
    margin-bottom: 2.5em;
    max-width: $max-width-mobile;
    @include desktop() {
        margin: 0.6em auto;
    }
}

.about-skills-title {
    font-weight: 300;
    margin: 0.7em 0;
}

.about-skills-description {
    color: textColor(secondary-text);
    font-size: 0.9em;
    margin: 0.2em 0;
    max-width: $max-width-mobile;
    @include desktop() {
        margin: 0.6em 0;
    }
}

.about-right-top-column {
    margin-top: 1.5em;
    grid-area: right-top;

    .about-hero-image-container{
        position: relative;
        display: none;
        @include desktop() {
            display: unset;
            max-width: unset;
        }
        .about-hero-image {
            width: 100%;
            @include desktop() {
                width: 15em;
            }
        }
    
        .about-hero-image-ref {
            font-size: .8em;
            color: textColor(faded-text);
            position: absolute;
            bottom: -1.5em;
            right: 0;
        }
    }
}

.about-right-bottom-column {
    grid-area: right-bottom;
}

.about-extra-button {
    @include desktop {
        display: none;
    }
}
