.projects-container {
    grid-area: container;
    display: grid;
    grid-template:
    'content' auto
    / auto;
    @include desktop() {
        margin-top: 5em;
    }
}

.projects-main-title {
    font-weight: 300;
    @include desktop() {
        display: none;
    }
}

.projects-project {
    margin-bottom: 3em;
    @include desktop() {
        display: grid;
        grid-template:
        '. image' 1fr
        'content-title image' auto
        'content-description image' auto
        'content-buttons image' auto
        '. image' 1fr
        / minmax(10em, 25.5em) minmax(10em, 25.5em);
        margin-bottom: 7em;
    }

    &.mirrored {
        @include desktop() {
            display: grid;
            grid-template:
            'image .' 1fr
            'image content-title' auto
            'image content-description' auto
            'image content-buttons' auto
            'image .' 1fr
            / minmax(10em, 27em) minmax(10em, 27em);
        }

        .projects-project-title {
            @include desktop() {
                margin-left: 2em;
            }
        }

        .projects-projects-description {
            @include desktop() {
                margin-left: 3em;
            }
        }

        .projects-project-buttons {
            @include desktop() {
                margin-left: 3em;
            }
        }

        .projects-project-image {
            @include desktop() {
                grid-area: image;
                margin-left: 0;
            }
        }
    }

    .projects-project-title {
        font-weight: 300;
        @include desktop() {
            grid-area: content-title;
        }
    }

    .projects-project-image {
        width: 100%;
        border-radius: 3px;
        border: 0.5px solid #969696;
        @include desktop() {
            grid-area: image;
            margin-left: 2.5em;
            max-width: 26em;
        }
    }

    .projects-projects-description {
        color: textColor(secondary-text);
        @include desktop() {
            grid-area: content-description
        }
    }

    .projects-project-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        @include desktop() {
            grid-area: content-buttons;
            max-width: 25em;
        }
    }
}

