.home-container {
    grid-area: container;
    display: grid;
    grid-template:
    'content' auto
    'image' auto
    / auto;
    @include desktop() {
        margin-top: 6em;
        grid-template:
        'content . image' auto
        / auto minmax(0.5em, 15em) auto;
    }
}

.home-left-column {
    grid-area: content;
    @include desktop() {
        max-width: 23em;
        display: flex;
        flex-direction: column;
        align-items: initial;
        justify-content: flex-start;
    }
}

.home-main-title {
    color: #424242;
    font-size: 1.6em;
    line-height: 1.5em;
    margin: 0.9em auto;
    max-width: $max-width-mobile;
    @include desktop() {
        margin: 0.6em auto;
    }
}

.home-sub-title {
    color: textColor(secondary-text);
    font-size: 1.3em;
    margin: 0.9em auto;
    max-width: $max-width-mobile;
    @include desktop() {
        margin: 0.6em auto;
        margin-left: 0;
    }
}

.home-introduction {
    color: textColor(secondary-text);
    font-size: 1em;
    margin: 0.9em auto;
    max-width: $max-width-mobile;
    @include desktop() {
        margin: 0.6em auto;
    }
}

.home-learn-more {
    color: $primary-pink;
    white-space: nowrap;
    &:hover {
        color: $primary-blue;
    }
}

.home-buttons {
    margin-top: 2.5em;
}

.home-hero-image-container{
    grid-area: image;
    position: relative;
    @include desktop() {
        max-width: unset;
    }
    .home-hero-image {
        width: 100%;
        @include desktop() {
            width: 22em;
        }
    }

    .home-hero-image-ref {
        font-size: .8em;
        color: textColor(faded-text);
        position: absolute;
        bottom: -1.5em;
        right: 0;
    }
}